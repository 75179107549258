<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <GanttChart
          :projectId="id"
          @setSearchFilteringOptions="setSearchFilteringOptions"
        ></GanttChart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <IndividualProjectTable
          :title="`Tareas Hoy`"
          :projectId="id"
          :tableName="`todayTasks`"
        ></IndividualProjectTable>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12">
        <IndividualProjectTable
          :title="`Tareas Próximos dias`"
          :projectId="id"
          :tableName="`nextDayTasks`"
        ></IndividualProjectTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IndividualProjectTable from "./IndividualProjectTable.vue";
import GanttChart from "./GanttChart.vue";

export default {
  name: "individualproject",
  components: {
    IndividualProjectTable,
    GanttChart
  },
  data() {
    return {
      id: null
    };
  },
  computed: {},
  methods: {
    setSearchFilteringOptions(filterMainCredential) {
      this.id = filterMainCredential.projectId;
    }
  },
  async mounted() {
    this.id = this.$route.params.id;
  }
};
</script>

<style></style>
